<script setup lang="ts">
definePageMeta({
  colorMode: 'dark',
  layout: 'default',
})

const route = useRoute()
const menu = (route.params.menu as string | undefined)?.replace('-menu', '')

if (!menu) {
  throw createError({ fatal: true, statusCode: 404, statusMessage: 'Page not found' })
}

const { data: pageContent } = await useAsyncData(`${menu}-content`, () => queryContent(`pages/${menu}-menu`).findOne())
const { data: menuContent } = await useAsyncData(`${menu}-menu`, () => queryContent(`menus/${menu}`).findOne())

if (!pageContent || !menuContent) {
  throw createError({ fatal: true, statusCode: 404, statusMessage: 'Page not found' })
}

useSeoMeta({
  description: pageContent.value?.description,
  ogDescription: pageContent.value?.description,
  ogTitle: pageContent.value?.title,
  title: pageContent.value?.title,
})

 import.meta.prerender && defineOgImageComponent('LandingPage', {
  title: pageContent.value?.landing.title,
})
</script>

<template>
  <ULandingSection
    v-bind="pageContent?.landing"
    :ui="{
      title: 'font-serif text-outline dark:text-primary-100'
    }"
  >
    <ULandingCard class="lg:max-w-screen-sm m-auto menu-content">
      <ContentRenderer :value="menuContent"/>
    </ULandingCard>
  </ULandingSection>

  <ULandingSection>
    <ULandingCTA
      id="cta"
      v-bind="pageContent?.cta"
      :ui="{
        title: 'font-serif text-outline dark:text-primary-100'
      }"
    />
  </ULandingSection>
</template>
